import { Formik, Form } from 'formik';
import { func, shape } from 'prop-types';
import styled from 'styled-components';
import { Page, Subtitle, Content } from 'components/common/page';
import TextField from 'components/common/textField';
import SubmitButton from 'components/common/submitButton';
import { ReactComponent as TipIcon } from 'images/tipIcon.svg';
import partner from 'images/enrique.png';
import { normalizeMoney, formatMoney } from 'utils/normalizers';
import { useSendQuestion } from 'hooks/useSendQuestion';
import Yup from 'utils/yup';

const schema = Yup.object().shape({
  income: Yup.string()
    .money('Ingresa tu sueldo mensual.')
    .required('Ingresa tu sueldo mensual.'),
});

export function IncomeForm({ prevStep, nextStep, userInfo, setUserInfo }) {
  const { status, mutate } = useSendQuestion();
  const onSubmit = (values) => {
    const income = parseFloat(values.income.replace(/,/g, ''));
    mutate(
      { key: 'income', value: income },
      {
        onSuccess: () => {
          setUserInfo({
            ...userInfo,
            income,
          });
          nextStep();
        },
      },
    );
  };
  return (
    <Page>
      <Formik
        initialValues={{
          income: formatMoney(userInfo?.income || ''),
        }}
        onSubmit={onSubmit}
        validationSchema={schema}
        validateOnMount
      >
        {({ isValid }) => {
          return (
            <Form autoComplete="off">
              <Subtitle className="pt5">
                {userInfo?.name}, hablemos de dinero, platícanos
                <br />
                ¿Cuánto es tu ingreso mensual?
              </Subtitle>
              <Content>
                <TextField
                  name="income"
                  labelText="¿Cuál es tu ingreso mensual?"
                  autoFocus
                  normalize={normalizeMoney}
                  leftIcon="$"
                />
                <TipContainer>
                  <Img src={partner} alt="Enrique" />
                  <Tip>
                    <p className="dgt-f12 b">
                      Me refiero al saldo que ingresa libre a tu cuenta, ya sin
                      impuestos. &#128522;
                    </p>
                    <p className="dgt-f12 mt4">
                      Esto nos ayudará a saber si eres candidato a el crédito{' '}
                    </p>
                    <TipTail />
                  </Tip>
                </TipContainer>
              </Content>
              <SubmitButton
                type="submit"
                prevStep={prevStep}
                disabled={!isValid || status === 'loading'}
              >
                <span className="fw6">¡Listo!</span> Siguiente
              </SubmitButton>
            </Form>
          );
        }}
      </Formik>
    </Page>
  );
}

IncomeForm.propTypes = {
  prevStep: func.isRequired,
  nextStep: func.isRequired,
  userInfo: shape({}),
  setUserInfo: func.isRequired,
};

const TipContainer = styled.div.attrs({
  className: 'flex flex-row pt5 relative',
})`
  padding-left: 60px;
`;

const Img = styled.img.attrs({
  className: 'absolute',
})`
  width: 60px;
  height: 60px;
  left: -10px;
`;

const Tip = styled.div.attrs({
  className: 'relative w-100 dgt-tooltip-bg pa4 dgt-tooltip-color',
})`
  border-radius: 20px;
`;

const TipTail = styled(TipIcon).attrs({
  className: 'absolute',
})`
  bottom: -10px;
  left: -2px;
`;

export default IncomeForm;
