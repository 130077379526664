export const stepsKeys = {
  USER_INFO: 'user_info',
  CONTACT_INFO: 'contact_info',
  NIP: 'nip',
  OCCUPANCY: 'occupancy',
  LOCATION: 'location',
  RFC: 'rfc',
  REFERENCES: 'references',
  INCOME: 'income',
};

export const customStepsKeys = {
  BUTTONS_IN_GRID: 'buttons_in_grid',
  BUTTONS_IN_COLUMN: 'buttons_in_column',
};
