import * as Yup from 'yup';

Yup.addMethod(Yup.string, 'phone', function (errorMessage) {
  return this.test(`test-phone`, errorMessage, function (value) {
    const { path, createError } = this;
    return (
      (value && value.replace(/[\s()]/g, '').length === 10) ||
      createError({ path, message: errorMessage || 'not a valid phone' })
    );
  });
});

Yup.addMethod(Yup.string, 'money', function (errorMessage) {
  return this.test(`test-money`, errorMessage, function (value) {
    const { path, createError } = this;
    return (
      (value && parseFloat(value.replace(/[$,]/g, '')) > 0) ||
      createError({ path, message: errorMessage || 'not a valid money value' })
    );
  });
});

export default Yup;
