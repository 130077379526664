import { useQuery } from 'react-query';
import api from 'api';

async function getLocations({ queryKey }) {
  const [, zipCode] = queryKey;
  // ToDo: replace this endpoint for one from hapi hades
  const response = await api.get(
    `https://staging.digitt.com/api/v1/codigopostal/${zipCode}`,
  );
  return response.data;
}

export function useGetLocations(zipCode, options = {}) {
  return useQuery(['zipCode', zipCode], getLocations, {
    ...options,
  });
}
