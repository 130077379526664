import { func, shape, string } from 'prop-types';
import { Formik, Form } from 'formik';
import TextField from 'components/common/textField';
import SubmitButton from 'components/common/submitButton';
import { Page, Title, Subtitle, Content } from 'components/common/page';
import Yup from 'utils/yup';

const schema = Yup.object().shape({
  name: Yup.string().required('Ingresa tu nombre'),
  firstSurname: Yup.string().required('Ingresa tu apellido paterno'),
});

function UserInfoForm({ nextStep, userInfo, setUserInfo }) {
  const onSubmit = (values) => {
    localStorage.setItem('applicationState', JSON.stringify(values));
    setUserInfo({
      ...values,
    });
    nextStep();
  };

  return (
    <Page>
      <Formik
        initialValues={{
          name: userInfo?.name,
          firstSurname: userInfo?.firstSurname,
          secondSurname: userInfo?.secondSurname,
        }}
        onSubmit={onSubmit}
        validationSchema={schema}
        validateOnMount
      >
        {({ isValid }) => {
          return (
            <Form autoComplete="off">
              <Title>¡Hola! Soy Enrique</Title>
              <Subtitle>
                Te acompañaré en tu solicitud de crédito. ¿Comenzamos?
              </Subtitle>
              <Content>
                <TextField name="name" labelText="Nombre(s):" autoFocus />
                <TextField name="firstSurname" labelText="Apellido paterno:" />
                <TextField name="secondSurname" labelText="Apellido materno:" />
              </Content>
              <SubmitButton type="submit" disabled={!isValid}>
                <span className="fw6">¡Listo!</span> Siguiente
              </SubmitButton>
            </Form>
          );
        }}
      </Formik>
    </Page>
  );
}

UserInfoForm.propTypes = {
  nextStep: func.isRequired,
  userInfo: shape({
    name: string,
    firstSurname: string,
    secondSurname: string,
  }),
  setUserInfo: func.isRequired,
};

UserInfoForm.defaultProps = {
  userInfo: {},
};

export default UserInfoForm;
