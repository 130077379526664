import React, { useState } from 'react';
import { func, shape } from 'prop-types';
import styled from 'styled-components';
import CardsSelector from 'components/common/cardsSelector';
import TextField from 'components/common/textField';
import SubmitButton from 'components/common/submitButton';
import { HeaderText } from 'components/common/headerText';
import { useSendQuestion } from 'hooks/useSendQuestion';
import { Formik } from 'formik';

export default function ButtonsInGrid({
  nextStep,
  prevStep,
  userInfo,
  step,
  setUserInfo,
}) {
  const { name } = userInfo;
  const { status, mutate } = useSendQuestion();
  const {
    options: { choices, fieldName, hasOtherButton, sendToMeta },
  } = step;

  const submitWithMutate = (values) => {
    mutate(
      { key: fieldName, value: values[fieldName] },
      {
        onSuccess: () => {
          setUserInfo({
            ...userInfo,
            [fieldName]: values[fieldName],
          });
          nextStep();
        },
      },
    );
  };

  const onSubmit = (values) => {
    // sending values
    if (!sendToMeta) {
      submitWithMutate(values);
    } else {
      nextStep();
    }
  };

  const [showInput, setShowInput] = useState(false);

  return (
    <div className="w-100 flex justify-center flex-wrap pt5">
      {step.header.map((h, i) => (
        <HeaderText key={`${h.type}-${i}`} {...h} name={name} />
      ))}
      <Formik
        initialValues={{ [fieldName]: userInfo[fieldName] }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, handleReset, setFieldValue, values }) => (
          <form onSubmit={handleSubmit} onReset={handleReset} className="w-100">
            <CardsSelector
              items={choices}
              name={fieldName}
              onSubmit={onSubmit}
              type="submit"
              setFieldValue={setFieldValue}
              values={values}
            />
            {hasOtherButton && (
              <div className="w-100 flex justify-center">
                <div className="w-90 pa3">
                  <ExtraCard
                    onClick={async () => {
                      await setFieldValue(fieldName, '');
                      setShowInput(!showInput);
                    }}
                    type="button"
                    touched={showInput}
                  >
                    Otro
                  </ExtraCard>
                  {showInput && (
                    <div className="mt6">
                      <TextField
                        id="other"
                        name={fieldName}
                        labelText="Otro:"
                        autoComplete="off"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            <SubmitButton
              type="submit"
              prevStep={prevStep}
              disabled={!values[fieldName] || status === 'loading'}
              hideSubmitButton={!showInput}
            >
              <span className="fw6">¡Listo!</span> siguiente
            </SubmitButton>
          </form>
        )}
      </Formik>
    </div>
  );
}

ButtonsInGrid.propTypes = {
  nextStep: func.isRequired,
  prevStep: func.isRequired,
  userInfo: shape({}).isRequired,
  step: shape({}).isRequired,
  setUserInfo: func.isRequired,
};

const ExtraCard = styled.button.attrs({
  className: 'bg-white w-100 tc main fw6 pv4 bn',
})`
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  font-size: 15px;
  border-radius: 10px;
  background: ${(props) => props.touched && '#3E8AF8'};
  color: ${(props) => props.touched && '#FFFFFF'};
`;
