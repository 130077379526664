import types from './types';

export default function applicationReducer(state, action) {
  switch (action.type) {
    case types.SET_CURRENT_STEP: {
      return {
        ...state,
        currentStep: action.currentStep,
      };
    }
    case types.SET_USER_INFO: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...action.userInfo,
        },
      };
    }
    case types.SET_STEPS_INFO: {
      return {
        ...state,
        steps: action.data.steps,
        currentStep: action.data.currentStep,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
