import { useMutation } from 'react-query';
import api from 'api';

async function sendQuestion({ key, value }) {
  const response = await api({
    method: 'PUT',
    url: '/loan_request/question',
    data: { key, value },
    headers: { 'content-type': 'application/json' },
  });

  return response.data;
}

export function useSendQuestion() {
  const mutation = useMutation(sendQuestion);

  return mutation;
}
