import React, { useRef } from 'react';
import styled from 'styled-components';
import digitt from 'images/digitt-header.png';
import partner from 'images/enrique.png';

export const ApplicationHeader = (props) => {
  const { percent, title, ...rest } = props;

  return (
    <Header {...rest}>
      <div className="flex justify-between w-100">
        <div className="w-20 pv2 pl1">
          <Percent size="60" percent={percent} />
        </div>
        <Title>{title}</Title>
        <div className="w-25 pr1">
          <ImageIcon>
            <img src={digitt} alt="logo" className="logo" />
          </ImageIcon>
        </div>
      </div>
      <Partner>
        <div className="flex justify-center">
          <img src={partner} alt="partner" width="120" />
        </div>
      </Partner>
    </Header>
  );
};

export const Percent = (props) => {
  const { size, percent } = props;

  const diameter = size / 2;
  const radius = size / 2 - 15 / 2;
  const circumference = 2 * Math.PI * radius;
  const progress = (100 - Number(percent) / 100) * circumference;
  const totalSize = Number(size) + 1;
  return (
    <Percentage id="percent">
      <Svg width={totalSize} height={totalSize} transform={`rotate(-90)`}>
        <defs>
          <filter
            id="dropshadow"
            x="-20%"
            y="-20%"
            width="180%"
            height="180%"
            filterUnits="userSpaceOnUse"
          >
            <feDropShadow dx="1" dy="0" stdDeviation="5" floodColor="#BACCFD" />
          </filter>
        </defs>
        <Circle
          fill="#FFFFFF"
          stroke="#3E8AF8"
          cx={diameter}
          cy={diameter}
          r={radius}
          strokeWidth={2}
        />
        <circle
          fill="none"
          stroke="#c2c2c2"
          cx={diameter}
          cy={diameter}
          r={radius}
          strokeWidth={2}
          strokeDasharray={circumference}
          strokeDashoffset={progress}
          ref={useRef(0)}
        />
      </Svg>
      <div className="absolute flex justify-center items-center">
        <NumberPercentage>{percent}%</NumberPercentage>
      </div>
    </Percentage>
  );
};

const Title = styled.div.attrs({
  className: 'w-70 lighter flex pt2 justify-center',
})`
  font-size: 13px;
  line-height: 18px;
`;

const Header = styled.div.attrs({
  className: 'bg-white w-100 relative header_shadow',
})`
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
`;

const ImageIcon = styled.div.attrs({
  className: 'fr mr1 mv2',
})``;

const Partner = styled.div.attrs({
  className: 'absolute top-1 w-100',
})``;

// Percentage components
const Percentage = styled.div.attrs({
  className: 'flex items-center justify-center',
})``;

const Svg = styled.svg.attrs({
  className: 'flex justify-center',
})``;

const Circle = styled.circle.attrs({})`
  filter: url(#dropshadow);
`;

const NumberPercentage = styled.span.attrs({
  className: 'main fw6',
})`
  font-size: 11px;
  line-height: 18px;
`;
