export const choices = [
  {
    value: 'Estudiante',
    text: 'Estudiante',
  },
  {
    value: 'Negocio propio',
    text: 'Negocio propio',
  },
  {
    value: 'Empleado',
    text: 'Empleado',
  },
  {
    value: 'Tareas del hogar',
    text: 'Tareas del hogar',
  },
  {
    value: 'No trabajo',
    text: 'No trabajo',
  },
  {
    value: 'Retirado',
    text: 'Retirado',
  },
];

export const tenureOptions = [
  {
    value: '1',
    label: '1 año',
  },
  {
    value: '2',
    label: '2 años',
  },
  {
    value: '+3',
    label: '3-5 años',
  },
  {
    value: '+6',
    label: '6-8 años',
  },
  {
    value: '+9',
    label: '9 + años',
  },
];
