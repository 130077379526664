import { useState, useEffect } from 'react';
import { string } from 'prop-types';
import { useField } from 'formik';
import styled from 'styled-components';
import { ReactComponent as DownArrow } from 'images/downArrow.svg';
import { ReactComponent as CloseIcon } from 'images/closeIcon.svg';

export function Dropdown(props) {
  const { name, labelText, title, options, ...rest } = props;
  const [field, , helpers] = useField(name);
  const [open, setOpen] = useState(false);
  const selectedOption = options.find((o) => o.value === field.value) || {};

  const onItemSelect = (item) => () => {
    helpers.setValue(item.value);
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      window.document.body.style.overflow = 'hidden';
    } else {
      window.document.body.style.overflow = 'auto';
    }
  }, [open]);

  return (
    <>
      <DropdownContaienr {...rest} onClick={() => setOpen(true)}>
        {selectedOption.label || title || 'Selecciona una opción'}
        <DownArrow />
      </DropdownContaienr>
      {open && (
        <MenuContainer>
          <MenuOpacity onClick={() => setOpen(false)} />
          <MenuItems>
            <Header>
              {labelText}
              <CloseIcon onClick={() => setOpen(false)} />
            </Header>
            <Items>
              {options.map((o) => (
                <Item
                  onClick={onItemSelect(o)}
                  key={o.value}
                  selected={o.value === selectedOption.value}
                >
                  {o.label}
                </Item>
              ))}
            </Items>
          </MenuItems>
        </MenuContainer>
      )}
    </>
  );
}

Dropdown.propTypes = {
  name: string.isRequired,
  labelText: string,
};

Dropdown.defaultProps = {
  labelText: '',
};

export default Dropdown;

const DropdownContaienr = styled.div.attrs({
  className: 'bg-white main f5 b pv4 flex justify-between items-center pointer',
})`
  border-radius: 1.125rem;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  padding-left: 30px;
  padding-right: 20px;
`;

const MenuContainer = styled.div.attrs({
  className:
    'fixed h-100 w-100 z-999 top-0 left-0 flex flex-column justify-end relative',
})``;

const MenuOpacity = styled.div.attrs({
  className: 'fixed h-100 w-100 top-0 left-0',
})`
  background: rgba(163, 180, 201, 0.38);
  z-index: -1;
`;

const MenuItems = styled.div.attrs({
  className: 'bg-white overflow-y-scroll flex justify-center',
})`
  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background: #c4c4c4;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  border-radius: 30px 30px 0px 0px;
  animation: moveUpWithFade 0.3s linear;
  min-height: 30%;
  max-height: 70%;

  @keyframes moveUpWithFade {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0%);
      opacity: 1;
    }
  }
`;

const Header = styled.div.attrs({
  className:
    'ph4 pb3 mt1 pt4 flex justify-between items-center b fixed w-90 bg-white',
})`
  color: #a3b4c9;
`;

const Items = styled.div.attrs({
  className: 'mt6',
})``;

const Item = styled.div.attrs({
  className: 'tc dark-main b pa4',
})`
  color: ${(props) => props.selected && '#3E8AF8'};
`;
