import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';
import { HelmetProvider } from 'react-helmet-async';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import setupInterceptors from 'api/interceptors';
import Application from 'components/pages/application';

import 'styles/app.css';

const queryClient = new QueryClient();
setupInterceptors();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <Router>
          <Switch>
            <Route path="/solicitud-credito" component={Application} />
            <Redirect to="/solicitud-credito" />
          </Switch>
        </Router>
      </HelmetProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} position="top-right" /> */}
    </QueryClientProvider>
  );
}

export default App;
