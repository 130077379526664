import { func, shape, string } from 'prop-types';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import TextField from 'components/common/textField';
import SubmitButton from 'components/common/submitButton';
import Checkbox from 'components/common/checkbox';
import { Page, Subtitle, Content } from 'components/common/page';
import { normalizePhone } from 'utils/normalizers';
import { useCreateApplication } from 'hooks/useCreateApplication';
import { stepsKeys } from 'constants/enums';
import Yup from 'utils/yup';

const schema = Yup.object().shape({
  email: Yup.string().required('Ingresa tu correo').email('Correo inválido'),
  phone: Yup.string()
    .required('Ingresa tu número de celular')
    .phone('Ingresa un número de celular de 10 dígitos'),
});

function ContactInfoForm({
  nextStep,
  prevStep,
  userInfo,
  setUserInfo,
  setStepsInfo,
}) {
  const history = useHistory();
  const { mutateAsync, status } = useCreateApplication();
  const onSubmit = async (values) => {
    const { token, schema_body: schema } = await mutateAsync(values);
    setUserInfo({
      ...userInfo,
      ...values,
    });
    history.push(`/solicitud-credito?t=${token}`);
    setStepsInfo({ steps: schema, currentStep: stepsKeys.NIP });
    nextStep();
  };

  const preventSelection = (e) => {
    e.stopPropagation();
  };

  return (
    <Page>
      <Formik
        initialValues={{
          email: userInfo?.email,
          phone: userInfo?.phone,
          accept: false,
        }}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        {({ isValid, values }) => {
          return (
            <Form>
              <Subtitle className="pt5">
                Gracias {userInfo?.name}, ahora necesitamos un correo y un
                teléfono
              </Subtitle>
              <Content>
                <TextField
                  name="email"
                  labelText="Correo:"
                  autoComplete="off"
                  type="email"
                  autoFocus
                />
                <TextField
                  name="phone"
                  labelText="Teléfono:"
                  autoComplete="off"
                  type="tel"
                  normalize={normalizePhone}
                />
                <Checkbox
                  containerClass="mt5"
                  name="accept"
                  label={
                    <>
                      He leído y acepto el{' '}
                      <a
                        href="https://digitt.com/aviso-de-privacidad.html"
                        target="_blank"
                        rel="noreferrer"
                        onClick={preventSelection}
                        className="main"
                      >
                        Aviso de Privacidad
                      </a>{' '}
                      y los{' '}
                      <a
                        href="https://digitt.com/terminos-y-condiciones.html"
                        target="_blank"
                        rel="noreferrer"
                        onClick={preventSelection}
                        className="main"
                      >
                        Términos y Condiciones
                      </a>{' '}
                      de uso.
                    </>
                  }
                />
              </Content>
              <SubmitButton
                type="submit"
                hideSubmitButton={!isValid || !values.accept}
                disabled={status === 'loading'}
                prevStep={prevStep}
              >
                <span className="fw6">¡Listo!</span> Siguiente
              </SubmitButton>
            </Form>
          );
        }}
      </Formik>
    </Page>
  );
}

ContactInfoForm.propTypes = {
  nextStep: func.isRequired,
  userInfo: shape({
    name: string,
    firstSurname: string,
    secondSurname: string,
  }).isRequired,
  setUserInfo: func.isRequired,
  setStepsInfo: func.isRequired,
};

export default ContactInfoForm;
