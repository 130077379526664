import { useCallback, useEffect, useState } from 'react';
import { func, string, shape } from 'prop-types';
import { Page, Subtitle, Content } from 'components/common/page';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { ReactComponent as LoadingIcon } from 'images/loading.svg';
import { ReactComponent as CheckBlueIcon } from 'images/check.svg';
import SubmitButton from 'components/common/submitButton';
import { useValidateNip } from 'hooks/useValidateNip';
function NipForm({ nextStep, prevStep, userInfo }) {
  const [nipArray, setNipArray] = useState(() => Array(5).fill(''));
  const [hasBeenFilled, setHasBeenFilled] = useState(false);
  const { mutate, status } = useValidateNip();
  const {
    handleSubmit,
    values: { nip },
    setFieldValue,
  } = useFormik({
    initialValues: {
      nip: '',
    },
    onSubmit,
  });

  const goNext = useCallback(() => {
    nextStep();
  }, [nextStep]);

  useEffect(() => {
    const currentNip = nipArray.join('');
    setFieldValue('nip', currentNip);
    if (currentNip.length === 4) {
      setHasBeenFilled(true);
      mutate({ nip: currentNip }, { onSuccess: goNext });
    }
  }, [nipArray, setFieldValue, setHasBeenFilled, mutate, goNext]);

  const onKeyDown = (e) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      e.preventDefault();
      return;
    }

    if (e.keyCode === 8 && e.target.value.length === 0) {
      const [, num] = e.target.name.split('_');
      const numberKey = parseInt(num, 10);
      if (numberKey > 1) {
        document.querySelector(`input[name='digit_${numberKey - 1}']`).focus();
      }
    }
  };

  const onChange = (e) => {
    const [, num] = e.target.name.split('_');
    const numberKey = parseInt(num, 10);
    const newNipArray = [...nipArray];
    newNipArray[numberKey - 1] = e.target.value;
    setNipArray(newNipArray);
    if (numberKey < 4 && e.target.value !== '') {
      document.querySelector(`input[name='digit_${numberKey + 1}']`).focus();
    }
  };

  function onSubmit() {
    goNext();
  }

  return (
    <Page>
      <form onSubmit={handleSubmit}>
        <Subtitle className="pt5">
          {userInfo?.name}, por favor ayúdanos a confirmar tu número. Hemos
          enviado un código por Whatsapp a tu telefono.
        </Subtitle>
        <Content>
          <NipContainer>
            {[...Array(4).keys()].map((n) => (
              <NipInput
                name={`digit_${n + 1}`}
                key={n}
                type="tel"
                maxLength="1"
                onKeyDown={onKeyDown}
                onChange={onChange}
                autoFocus={n === 0 && nip?.length < 1}
                autoComplete="off"
                error={status === 'error'}
              />
            ))}
            {status === 'success' && nip?.length === 4 && (
              <CheckWrapper>
                <CheckBlueIcon />
              </CheckWrapper>
            )}
          </NipContainer>
          {status === 'error' && (
            <Container>
              <ErrorMessage>Código equivocado</ErrorMessage>
              <RetryNipButton type="button">
                Volver a enviar código
              </RetryNipButton>
            </Container>
          )}
        </Content>
        <SubmitButton
          type="submit"
          hideSubmitButton={!hasBeenFilled}
          disabled={
            nip?.length < 4 || status === 'loading' || status === 'error'
          }
          prevStep={prevStep}
        >
          <span className="fw6">¡Listo!</span> siguiente
        </SubmitButton>
      </form>
      {status === 'loading' && (
        <LoaderWrapper>
          <IconContainer>
            <LoadingIcon />
          </IconContainer>
        </LoaderWrapper>
      )}
    </Page>
  );
}

NipForm.propTypes = {
  nextStep: func.isRequired,
  prevStep: func.isRequired,
  userInfo: shape({
    name: string,
  }).isRequired,
};

const Container = styled.div`
  @media (min-width: 768px) {
    margin: 0 auto;
    width: 400px;
  }
`;

const NipContainer = styled(Container).attrs({
  className: 'flex justify-between ph4 relative',
})``;

const NipInput = styled.input.attrs({
  className: 'f3 main tc br4 b--main b--solid',
})`
  height: 80px;
  width: 60px;
  outline: none;
  ${({ error }) =>
    error &&
    `
    border-color: #F76689;
    color: #F76689;
  `}
`;

const LoaderWrapper = styled.div.attrs({
  className: 'fixed w-100 h-100 top-0 flex justify-center items-center',
})`
  background: rgba(163, 180, 201, 0.6);
`;

const IconContainer = styled.div.attrs({
  className: 'bg-white pa4 br4',
})``;

const ErrorMessage = styled.div.attrs({
  className: 'pv4 ph5 mv3 dgt-f12',
})`
  color: #f76689;
`;

const RetryNipButton = styled.button.attrs({
  className: 'pa3 ml4 main bg-white b--main b--solid f5 br3 pointer',
})`
  width: 185px;
`;

const CheckWrapper = styled.div.attrs({
  className: 'bg-main absolute right-0 br-100 b--solid b--white',
})`
  bottom: -10px;
  padding: 4px 6px;
  border-width: 4px;
`;

export default NipForm;
