import { func, shape } from 'prop-types';
import { useFormik } from 'formik';
import { Page, Content } from 'components/common/page';
import { HeaderText } from 'components/common/headerText';
import { SubmitButton } from 'components/common/submitButton';
import { ButtonCard } from 'components/common/card';
import { useSendQuestion } from 'hooks/useSendQuestion';

export function ButtonsInColumn({
  nextStep,
  prevStep,
  userInfo,
  step,
  setUserInfo,
}) {
  const {
    header,
    options: { choices, fieldName },
  } = step;
  const { status, mutate } = useSendQuestion();
  const { setFieldValue, values, handleSubmit } = useFormik({
    initialValues: {
      [fieldName]: (userInfo || {})[fieldName] || '',
    },
    onSubmit: onSubmit,
  });

  const onClick = (value) => () => {
    setFieldValue(fieldName, value);
  };

  function onSubmit(values) {
    mutate(
      { key: fieldName, value: values[fieldName] },
      {
        onSuccess: () => {
          setUserInfo({
            ...userInfo,
            [fieldName]: values[fieldName],
          });
          nextStep();
        },
      },
    );
    nextStep();
  }

  return (
    <Page>
      <div className="pt5">
        {header.map((h, i) => (
          <HeaderText key={`${h.type}-${i}`} {...h} name={userInfo?.name} />
        ))}
      </div>
      <Content>
        <form onSubmit={handleSubmit}>
          {choices.map((c, i) => (
            <ButtonCard
              key={`${c}-${i}`}
              type="submit"
              onClick={onClick(c.value)}
              active={values[fieldName] === c.value}
              disabled={status === 'loading'}
            >
              {c.text}
            </ButtonCard>
          ))}
        </form>
      </Content>
      <SubmitButton type="submit" hideSubmitButton prevStep={prevStep} />
    </Page>
  );
}

ButtonsInColumn.propTypes = {
  nextStep: func.isRequired,
  prevStep: func.isRequired,
  userInfo: shape({}).isRequired,
  step: shape({}).isRequired,
  setUserInfo: func.isRequired,
};

export default ButtonsInColumn;
