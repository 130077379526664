import { useMutation } from 'react-query';
import api from 'api';

async function setAddress({
  street,
  zipCode,
  colony,
  state,
  city,
  extNumber,
  intNumber,
}) {
  const response = await api({
    method: 'PUT',
    url: '/loan_request/address',
    data: {
      street,
      zip_code: zipCode,
      colony,
      state,
      city,
      ext_number: extNumber,
      int_number: intNumber || undefined,
    },
    headers: { 'content-type': 'application/json' },
  });

  return response.data;
}

export function useSetAddress() {
  const mutation = useMutation(setAddress);

  return mutation;
}
