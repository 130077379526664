import { useMutation } from 'react-query';
import api from 'api';

async function setRFC({ name, firstSurname, secondSurname, birthday }) {
  const data = await api.post('loan_request/rfc', {
    name,
    first_surname: firstSurname,
    second_surname: secondSurname,
    birthday,
  });

  return data;
}

export function useRFC() {
  return useMutation(setRFC);
}
