import { stepsKeys } from 'constants/enums';

export function getInitialState(applicationState) {
  const userInfo = {};
  const { schema, last_step: lastStep } = applicationState;
  let currentStep = schema.find((q) => q.key === lastStep);

  // This happens only when the nip step is not yet finished
  if (!currentStep) {
    currentStep = schema.find((q) => q.key === stepsKeys.NIP);
  }

  userInfo.name = applicationState?.personal_info?.name || '';
  userInfo.firstSurname = applicationState?.personal_info?.first_surname || '';
  userInfo.secondSurname =
    applicationState?.personal_info?.second_surname || '';
  userInfo.email = applicationState?.email || '';
  userInfo.phone = applicationState?.phone || '';
  userInfo.occupancyType = applicationState?.occupancy?.occupancy_type || '';
  userInfo.occupancyInstitution =
    applicationState?.occupancy?.institution || '';
  userInfo.occupancyTenure = applicationState?.occupancy?.tenure || '';
  userInfo.marital_status = applicationState?.marital_status || '';
  userInfo.scholarity = applicationState?.occupancy?.level_of_studies || '';
  userInfo.housing_type = applicationState?.housing_type || '';
  userInfo.street = applicationState?.address?.street || '';
  userInfo.zipCode = applicationState?.address?.zip_code || '';
  userInfo.colony = applicationState?.address?.colony || '';
  userInfo.state = applicationState?.address?.state || '';
  userInfo.city = applicationState?.address?.city || '';
  userInfo.extNumber = applicationState?.address?.ext_number || '';
  userInfo.intNumber = applicationState?.address?.int_number || '';
  userInfo.economic_dependents = applicationState?.economic_dependents || null;
  userInfo.income = applicationState?.occupancy?.income || null;

  const state = localStorage.getItem('applicationState');
  if (state) {
    const currentState = JSON.parse(state);
    userInfo.name = currentState.name || '';
    userInfo.firstSurname = currentState.firstSurname || '';
    userInfo.secondSurname = currentState.secondSurname || '';
  }

  return {
    userInfo,
    currentStep,
  };
}
