import { node, string } from 'prop-types';
import { useField } from 'formik';
import styled from 'styled-components';
import check from 'images/check.svg';

export default function Checkbox(props) {
  const { label, containerClass } = props;
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(props);

  const touch = () => {
    helpers.setTouched(true, true);
  };

  const onClick = () => {
    helpers.setValue(!field.value);
    touch();
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 32) {
      onClick();
    }
  };

  return (
    <ChecboxContainer className={containerClass}>
      <CheckboxButton
        aria-checked={!!field.value}
        role="checkbox"
        className={field.value ? 'active' : ''}
        tabIndex="0"
        onBlur={touch}
        onClick={onClick}
        onKeyDown={onKeyDown}
      />
      <Label onClick={onClick}>{label}</Label>
      <input type="checkbox" className="dn" {...field} />
    </ChecboxContainer>
  );
}

Checkbox.propTypes = {
  label: node,
  containerClass: string,
};

Checkbox.defaultProps = {
  label: null,
  containerClass: '',
};

const ChecboxContainer = styled.div.attrs({
  className: 'inline-flex relative',
})``;

const CheckboxButton = styled.div.attrs({
  className: 'absolute left-0 br2',
})`
  display: inline-block;
  transition: border-color 0.2s ease;
  margin: 0;
  border: 2px solid #95b0d7;
  cursor: pointer;
  width: 33px;
  height: 33px;
  vertical-align: middle;

  &:before {
    display: block;
    transform: scale(0);
    transition: opacity 0.2s ease, transform 0.3s ease, border-radius 0.3s ease;
    opacity: 0;
    background-color: #3e8af8;
    background-image: url(${check});
    background-repeat: no-repeat;
    background-position: center;
    content: '';
  }

  &:hover,
  &.active {
    border-color: #3e8af8;
  }

  &.active {
    &:before {
      transform: scale(1);
      opacity: 1;
      width: 25px;
      height: 25px;
      border: 2px solid #3e8af8;
    }
  }
`;

const Label = styled.label.attrs({
  className: 'f5',
})`
  padding-left: 44px;
`;
