import * as React from 'react';
import defaultSteps from './steps';
import applicationReducer from './applicationReducer';
import types from './types';

const ApplicationContext = React.createContext();

export function ApplicationProvider({ children }) {
  const [{ steps, currentStep, userInfo }, dispatch] = React.useReducer(
    applicationReducer,
    {
      steps: defaultSteps,
      currentStep: defaultSteps[0],
      userInfo: null,
    },
  );

  const setCurrentStep = React.useCallback(
    (current) => {
      dispatch({
        type: types.SET_CURRENT_STEP,
        currentStep: current,
      });
    },
    [dispatch],
  );

  const nextStep = React.useCallback(() => {
    const index = steps.findIndex((s) => s.key === currentStep.key);
    if (index < steps.length - 1) {
      setCurrentStep(steps[index + 1]);
    }
  }, [steps, currentStep, setCurrentStep]);

  const prevStep = React.useCallback(() => {
    const index = steps.findIndex((s) => s.key === currentStep.key);
    if (index > 0) {
      setCurrentStep(steps[index - 1]);
    }
  }, [steps, currentStep, setCurrentStep]);

  const setUserInfo = React.useCallback(
    (info) => {
      dispatch({
        type: types.SET_USER_INFO,
        userInfo: info,
      });
    },
    [dispatch],
  );

  const setStepsInfo = React.useCallback(
    ({ steps, currentStep }) => {
      dispatch({
        type: types.SET_STEPS_INFO,
        data: { steps, currentStep },
      });
    },
    [dispatch],
  );

  const contextValue = React.useMemo(
    () => ({
      steps,
      currentStep,
      userInfo,
      setCurrentStep,
      nextStep,
      prevStep,
      setUserInfo,
      setStepsInfo,
    }),
    [
      steps,
      currentStep,
      userInfo,
      setCurrentStep,
      nextStep,
      prevStep,
      setUserInfo,
      setStepsInfo,
    ],
  );

  return (
    <ApplicationContext.Provider value={contextValue}>
      {children}
    </ApplicationContext.Provider>
  );
}

export function useApplication() {
  const context = React.useContext(ApplicationContext);
  if (context === undefined) {
    throw new Error('useApplication must be used within a ApplicationProvider');
  }
  return context;
}
