import { string, arrayOf, shape, oneOfType, number } from 'prop-types';
import styled from 'styled-components';
import { Card } from '../card';

export default function CardsSelector({
  items,
  name,
  onSubmit,
  type,
  children,
  completeRows,
  setFieldValue,
  values,
  ...rest
}) {
  return (
    <div className="flex justify-center flex-wrap">
      <Container {...rest}>
        {items.map((item, i) => (
          <ItemContainer
            key={`${item.value}-${i}`}
            className={
              completeRows || (i === items.length - 1 && (i + 1) % 2 !== 0)
                ? 'w-100'
                : 'w-50'
            }
          >
            <Card
              onClick={() => {
                setFieldValue(name, item.value, false);
              }}
              type={type}
              active={values[name] === item.value}
            >
              {item.text}
            </Card>
          </ItemContainer>
        ))}
      </Container>
      {children}
    </div>
  );
}

const Container = styled.div.attrs({
  className: 'flex justify-between flex-wrap w-90',
})``;

const ItemContainer = styled.div.attrs({
  className: 'pa3',
})`
  @media (max-width: 368px) {
    width: 100%;
  }
`;
CardsSelector.propTypes = {
  items: arrayOf(
    shape({
      value: oneOfType([string, number]),
    }),
  ),
  name: string,
  type: string,
};
