import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ApplicationHeader } from 'components/common/applicationHeader';
import { useApplication } from 'providers/applicationProvider';
import { stepsKeys, customStepsKeys } from 'constants/enums';
import UserInfoForm from '../userInfoForm';
import ContactInfoForm from '../contactInfoForm';
import NipForm from '../nipForm';
import OccupancyForm from '../occupancyForm';
import RFCForm from '../rfcForm';
import ReferencesForm from '../references';
import ButtonsInGrid from '../buttonsInGrid';
import ButtonsInColumn from '../buttonsInColumn';
import LocationForm from '../locationForm';
import IncomeForm from '../incomeForm';
import { useGetApplicationState } from 'hooks/useGetApplicationState';

const baseSteps = {
  [stepsKeys.USER_INFO]: UserInfoForm,
  [stepsKeys.CONTACT_INFO]: ContactInfoForm,
  [stepsKeys.NIP]: NipForm,
  [stepsKeys.OCCUPANCY]: OccupancyForm,
  [stepsKeys.RFC]: RFCForm,
  [stepsKeys.LOCATION]: LocationForm,
  [stepsKeys.REFERENCES]: ReferencesForm,
  [stepsKeys.INCOME]: IncomeForm,
};

const customSteps = {
  [customStepsKeys.BUTTONS_IN_GRID]: ButtonsInGrid,
  [customStepsKeys.BUTTONS_IN_COLUMN]: ButtonsInColumn,
};

const FormContainer = styled.div.attrs({
  className: 'mt5 pb6',
})``;

function Form() {
  const location = useLocation();
  const {
    currentStep,
    steps,
    nextStep,
    prevStep,
    setUserInfo,
    userInfo,
    setStepsInfo,
  } = useApplication();
  const { status } = useGetApplicationState({
    enabled: !userInfo && !!new URLSearchParams(location.search).get('t'),
  });

  const stepIndex = steps.findIndex((s) => s.key === currentStep.key);
  const Component = baseSteps[currentStep.key] || customSteps[currentStep.type];

  if (!Component || ['loading', 'error'].includes(status)) {
    return null;
  }

  return (
    <>
      <ApplicationHeader
        percent={Math.ceil(((stepIndex + 1) / steps.length) * 100)}
        title="1. Solicitud - Datos personales"
      />
      <FormContainer>
        <Component
          step={currentStep}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          prevStep={prevStep}
          nextStep={nextStep}
          setStepsInfo={setStepsInfo}
        />
      </FormContainer>
    </>
  );
}

export default Form;
