import styled from 'styled-components';

export const Card = styled.button.attrs({
  className: 'bg-white w-100 tc main fw6 pv4 bn',
})`
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  font-size: 15px;
  border-radius: 10px;
  background: ${(props) => props.active && '#3E8AF8'};
  color: ${(props) => props.active && '#FFFFFF'};
`;

export const ButtonCard = styled(Card).attrs({
  className: 'mt4',
})`
  padding: 20px 0;
`;

export default Card;
