import { func, shape, string } from 'prop-types';
import { Formik, Form } from 'formik';
import { isValid, isAfter, parseISO, sub } from 'date-fns';
import TextField from 'components/common/textField';
import SubmitButton from 'components/common/submitButton';
import { Page, Title, Subtitle, Content } from 'components/common/page';
import Dropdown from 'components/common/dropdown';
import styled from 'styled-components';
import { normalizeDay, normalizeYear } from 'utils/normalizers';
import { gender, month } from './values';
import { useRFC } from 'hooks/useRFC';
import Yup from 'utils/yup';

RFCForm.propTypes = {
  nextStep: func.isRequired,
  userInfo: shape({
    name: string,
    firstSurname: string,
    secondSurname: string,
  }),
  setUserInfo: func.isRequired,
};

const schema = Yup.object().shape({
  name: Yup.string().required('Ingresa tu nombre'),
  firstSurname: Yup.string().required('Ingresa tu apellido paterno'),
  day: Yup.number().required('Ingresa un dia valido'),
  month: Yup.string().required('Ingresa un mes valido'),
  year: Yup.number()
    .test('len', 'Deben de ser 4 digitos', (val) => String(val)?.length >= 4)
    .required('Ingresa un año valido'),
  isValid: Yup.string()
    .ensure()
    .when(['year', 'month', 'day'], {
      is: (year, month, day) => {
        if (!(year && String(year).length === 4 && month && day)) return '';
        const birthday = parseISO(
          `${year}-${month}-${day?.length === 1 ? `0${day}` : day}`,
        );

        return (
          !isValid(birthday) ||
          !isAfter(birthday, parseISO('1920-01-01')) ||
          isAfter(birthday, sub(new Date(), { years: 5 }))
        );
      },
      then: Yup.string().required('Fecha de nacimiento invalida'),
    }),
  gender: Yup.string().required('Ingresa un dia valido'),
});

export default function RFCForm({ prevStep, nextStep, userInfo, setUserInfo }) {
  const { mutateAsync, status } = useRFC();
  const onSubmit = async (values) => {
    const birthday = `${values.year}-${values.month}-${
      values.day.length === 1 ? `0${values.day}` : values.day
    }`;

    await mutateAsync({
      ...values,
      birthday,
    });

    setUserInfo({
      ...userInfo,
      ...values,
    });

    nextStep();
  };

  return (
    <Page>
      <Formik
        initialValues={{
          name: userInfo.name || '',
          firstSurname: userInfo.firstSurname || '',
          secondSurname: userInfo.secondSurname || '',
          gender: userInfo.gender || '',
          month: userInfo.month || '',
          day: userInfo.day || '',
          year: userInfo.year || '',
          isValid: '',
        }}
        onSubmit={onSubmit}
        validateOnMount
        validationSchema={schema}
      >
        {({ isValid, values, errors }) => (
          <Form>
            <Title />
            <Subtitle>
              Estamos por terminar, necesitamos estos datos personales
            </Subtitle>
            <Content>
              <TextField name="name" labelText="Nombre(s):" />
              <TextField name="firstSurname" labelText="Apellido paterno:" />
              <TextField name="secondSurname" labelText="Apellido materno:" />
              <Label className="mt5">¿Cuál es tu fecha de nacimiento?</Label>
              <input type="hidden" name="isValid" />
              <ErrorMessage>{errors.isValid}</ErrorMessage>
              <TextField name="day" labelText="Día" normalize={normalizeDay} />
              <Dropdown
                name="month"
                options={month}
                className="mb5 mt3"
                labelText="Mes"
                title="Mes"
              />
              <TextField
                name="year"
                labelText="Año"
                normalize={normalizeYear}
              />
              <Label className="mt5">¿Cuál es tu género?</Label>
              <Dropdown
                name="gender"
                options={gender}
                labelText="Género"
                title="Género"
              />
            </Content>
            <SubmitButton
              type="submit"
              prevStep={prevStep}
              disabled={!isValid || status === 'loading'}
              hideSubmitButton={false}
            >
              <span className="fw6">¡Listo!</span> siguiente
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </Page>
  );
}

const Label = styled.h5.attrs({
  className: 'f5 mt4',
})``;

const ErrorMessage = styled.div.attrs({
  className: 'state-error mv3',
})`
  font-size: 13px;
`;
