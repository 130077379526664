import styled from 'styled-components';
import { func, shape } from 'prop-types';
import { Formik, Form } from 'formik';
import { useMutation } from 'react-query';
import api from 'api';
import TextField from 'components/common/textField';
import Dropdown from 'components/common/dropdown';
import {
  Page,
  Title,
  Subtitle,
  Content,
  Comment,
} from 'components/common/page';
import SubmitButton from 'components/common/submitButton';
import { normalizePhone } from 'utils/normalizers';
import { kinship } from './values';
import Yup from 'utils/yup';

ReferencesForm.propTypes = {
  nextStep: func.isRequired,
  userInfo: shape({}),
  setUserInfo: func.isRequired,
};

const schema = Yup.object().shape({
  references: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Ingresa su nombre'),
        first_surname: Yup.string().required('Ingresa su apellido paterno'),
        phone: Yup.string()
          .required('Ingresa su número de celular')
          .phone('Ingresa un número de celular de 10 dígitos'),
        kinship: Yup.string().required('Ingresa tu relacion con tu referencia'),
      }),
    )
    .min(2, 'Ingresa 2 referencias'),
});

const otherValidation = (item) =>
  item.kinship === 'other' && !item.other.length
    ? 'Ingresa tu relacion con tu referencia'
    : '';

export default function ReferencesForm({
  prevStep,
  nextStep,
  userInfo,
  setUserInfo,
}) {
  const { mutateAsync } = useMutation((references) =>
    api.post('loan_request/references', references),
  );

  const onSubmit = async (values) => {
    const references = values.references.map((val) => ({
      ...val,
      kinship: val.kinship === 'other' ? val.other : val.kinship,
    }));

    await mutateAsync({ references });

    setUserInfo({
      ...userInfo,
      ...values,
    });

    nextStep();
  };

  const { references = [] } = userInfo;

  return (
    <Page>
      <Formik
        initialValues={{
          references: [
            {
              name: references[0]?.name || '',
              first_surname: references[0]?.first_surname || '',
              second_surname: references[0]?.second_surname || '',
              phone: references[0]?.phone || '',
              kinship: references[0]?.kinship || '',
              other: references[0]?.other || '',
            },
            {
              name: references[1]?.name || '',
              first_surname: references[1]?.first_surname || '',
              second_surname: references[1]?.second_surname || '',
              phone: references[1]?.phone || '',
              kinship: references[1]?.kinship || '',
              other: references[1]?.other || '',
            },
          ],
        }}
        onSubmit={onSubmit}
        validateOnMount
        validationSchema={schema}
      >
        {({ isValid, values, errors }) => (
          <Form>
            <Title />
            <Subtitle>
              ¿Nos compartes los datos de dos referencias que puedan validar tu
              información?
            </Subtitle>
            <Comment>
              Les mandaremos un SMS para corroborar que te conocen.
            </Comment>
            <Content>
              {values.references.map((item, index) => (
                <>
                  <Label>Referencia {index + 1}</Label>
                  <TextField
                    name={`references.${index}.name`}
                    labelText="Nombre"
                  />
                  <TextField
                    name={`references.${index}.first_surname`}
                    labelText="Apellido paterno"
                  />
                  <TextField
                    name={`references.${index}.second_surname`}
                    labelText="Apellido materno"
                  />
                  <TextField
                    name={`references.${index}.phone`}
                    labelText="Celular (10 dígitos):"
                    autoComplete="off"
                    type="tel"
                    normalize={normalizePhone}
                  />
                  <Dropdown
                    name={`references.${index}.kinship`}
                    options={kinship}
                    className="mb5 mt3"
                    labelText="Parentesco"
                    title="Parentesco"
                  />
                  {values.references[index].kinship === 'other' && (
                    <TextField
                      name={`references.${index}.other`}
                      labelText="Escribe otro parentesco"
                      validate={() => otherValidation(values.references[index])}
                    />
                  )}
                </>
              ))}
            </Content>
            <SubmitButton
              type="submit"
              prevStep={prevStep}
              disabled={!isValid}
              hideSubmitButton={false}
            >
              <span className="fw6">¡Listo!</span> siguiente
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </Page>
  );
}

const Label = styled.h4.attrs({
  className: 'f4 mt5 main',
})``;
