export default [
  {
    key: 'user_info',
  },
  {
    key: 'contact_info',
  },
  {
    key: 'nip',
  },
  {
    key: 'where_did_you_find_us',
    type: 'buttons_in_grid',
    header: [
      {
        type: 'subtitle',
        text: ['{{name}} platícame,', '¿Dónde te enteraste de nosotros?'],
      },
    ],
    options: {
      fieldName: 'utc_form',
      hasOtherButton: true,
      sendToMeta: true,
      choices: [
        {
          value: 'facebook',
          text: 'Facebook',
        },
        {
          value: 'instagram',
          text: 'Instagram',
        },
        {
          value: 'linkedin',
          text: 'Linkedin',
        },
        {
          value: 'recommendation',
          text: 'Recomendación',
        },
        {
          value: 'blog',
          text: 'Blog',
        },
        {
          value: 'radio',
          text: 'Radio',
        },
        {
          value: 'podcast',
          text: 'Podcast',
        },
        {
          value: 'google',
          text: 'Google',
        },
      ],
    },
  },
  {
    key: 'occupancy',
  },
  {
    key: 'marital_status',
    type: 'buttons_in_column',
    header: [
      {
        type: 'subtitle',
        text: ['¿Cuál es tu estado civil?'],
      },
    ],
    options: {
      fieldName: 'marital_status',
      choices: [
        {
          value: 'SOLTERO',
          text: 'Soltero',
        },
        {
          value: 'CASADO',
          text: 'Casado',
        },
        {
          value: 'DIVORCIADO',
          text: 'Divorciado',
        },
        {
          value: 'SEPARADO',
          text: 'Separado',
        },
        {
          value: 'VIUDO',
          text: 'Viudo',
        },
        {
          value: 'UNION_LIBRE',
          text: 'Retirado',
        },
      ],
    },
  },
  {
    key: 'scholarity',
    type: 'buttons_in_grid',
    header: [
      {
        type: 'subtitle',
        text: ['¿Cuál es tu nivel de estudios?'],
      },
    ],
    options: {
      fieldName: 'scholarity',
      hasOtherButton: false,
      sendToMeta: false,
      choices: [
        {
          value: 'Primaria',
          text: 'Primaria',
        },
        {
          value: 'Secundaria',
          text: 'Secundaria',
        },
        {
          value: 'Preparatoria',
          text: 'Preparatoria',
        },
        {
          value: 'Licenciatura',
          text: 'Licenciatura',
        },
        {
          value: 'Posgrado',
          text: 'Posgrado',
        },
        {
          value: 'Sin estudios',
          text: 'Sin estudios',
        },
      ],
    },
  },
  {
    key: 'housing_type',
    type: 'buttons_in_column',
    header: [
      {
        type: 'subtitle',
        text: ['{{name}}, el hogar en el que vives es:'],
      },
    ],
    options: {
      fieldName: 'housing_type',
      choices: [
        {
          value: 'PROPIO',
          text: 'Propio',
        },
        {
          value: 'RENTADO',
          text: 'Rentado',
        },
        {
          value: 'FAMILIA',
          text: 'Vivo con familiares',
        },
      ],
    },
  },
  {
    key: 'economic_dependents',
    type: 'buttons_in_grid',
    header: [
      {
        type: 'subtitle',
        text: ['¿Cuántas personas dependen de ti económicamente?'],
      },
    ],
    options: {
      fieldName: 'economic_dependents',
      hasOtherButton: false,
      sendToMeta: false,
      choices: [
        {
          value: 1,
          text: '1',
        },
        {
          value: 2,
          text: '2',
        },
        {
          value: 3,
          text: '3',
        },
        {
          value: 4,
          text: '4',
        },
        {
          value: 5,
          text: '5',
        },
        {
          value: 6,
          text: '6 +',
        },
        {
          value: 0,
          text: 'Ninguno',
        },
      ],
    },
  },
  {
    key: 'income',
  },
  {
    key: 'rfc',
  },
  { key: 'location' },
  {
    key: 'references',
  },
];
