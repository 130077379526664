import { useMutation } from 'react-query';
import api from 'api';

async function setLocation({ latitude, longitude }) {
  const response = await api.post('/loan_request/location', {
    latitude,
    longitude,
  });

  return response.data;
}

export function useSetLocaiton() {
  const mutation = useMutation(setLocation);

  return mutation;
}
