import { node, func, bool } from 'prop-types';
import styled from 'styled-components';
import back from 'images/back.png';

export function SubmitButton({
  children,
  prevStep,
  hideSubmitButton,
  ...rest
}) {
  return (
    <ButtonContainer
      className={prevStep ? 'justify-between' : 'justify-center'}
    >
      {prevStep && (
        <ButtonBack type="button" onClick={prevStep}>
          <img src={back} alt="back" />
        </ButtonBack>
      )}
      {!hideSubmitButton && (
        <ButtonSubmit type="submit" {...rest}>
          {children}
        </ButtonSubmit>
      )}
    </ButtonContainer>
  );
}

SubmitButton.propTypes = {
  children: node,
  prevStep: func,
  hideSubmitButton: bool,
};

SubmitButton.defaultProps = {
  children: null,
  prevStep: null,
  hideSubmitButton: false,
};

const ButtonSubmit = styled.button.attrs({
  className: 'w-70 bg-main white pv2 bn br2 fw3',
})`
  font-size: 15px;
  background: ${(props) => props.disabled && '#C4C4C4'};
`;

const ButtonContainer = styled.div.attrs({
  className: 'fixed bottom-0 bg-white w-100 pv4 ph4 flex items-center left-0',
})`
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  @media (max-width: 360px) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
`;

const ButtonBack = styled.button.attrs({
  className: 'fl bg-white ba b--main br2 flex items-center',
})`
  min-height: 2rem;
`;

export default SubmitButton;
