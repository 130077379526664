import { ApplicationProvider } from 'providers/applicationProvider';
import { Helmet } from 'react-helmet-async';

import Form from './form';

function Application() {
  return (
    <>
      <Helmet>
        <title>Digitt | Solicitud</title>
      </Helmet>
      <ApplicationProvider>
        <Form />
      </ApplicationProvider>
    </>
  );
}

export default Application;
