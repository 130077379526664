export const gender = [
  {
    value: 'M',
    label: 'Masculino',
  },
  {
    value: 'F',
    label: 'Femenino',
  },
];

export const month = [
  {
    value: '01',
    label: 'Enero',
  },
  {
    value: '02',
    label: 'Febrero',
  },
  {
    value: '03',
    label: 'Marzo',
  },
  {
    value: '04',
    label: 'Abril',
  },
  {
    value: '05',
    label: 'Mayo',
  },
  {
    value: '06',
    label: 'Junio',
  },
  {
    value: '07',
    label: 'Julio',
  },
  {
    value: '08',
    label: 'Agosto',
  },
  {
    value: '09',
    label: 'Septiembre',
  },
  {
    value: '10',
    label: 'Octubre',
  },
  {
    value: '11',
    label: 'Noviembre',
  },
  {
    value: '12',
    label: 'Diciembre',
  },
];
