import styled from 'styled-components';

export const Page = styled.div.attrs({
  className: 'mt5 pb6',
})``;

export const Title = styled.h3.attrs({
  className: 'main w-100 f4 tc mt6 mb4',
})``;

export const Subtitle = styled.h5.attrs({
  className: 'tc f5 w-80 center mt0 mb5',
})``;

export const Description = styled.p.attrs({
  className: 'f5 w-100 pt0 tc pb1',
})``;

export const Content = styled.div.attrs({
  className: 'flex flex-column w-90 center',
})``;

export const Comment = styled.div.attrs({
  className: 'w-100 tc',
})`
  font-size: 12px;
  color: #6e7b91;
`;

Page.Title = Title;
Page.Subtitle = Subtitle;
Page.Description = Description;

export default Page;
