import { useMutation } from 'react-query';
import api from 'api';

async function validateNip({ nip }) {
  const response = await api.post('/validate-nip', {
    nip,
  });

  return response.data;
}

export function useValidateNip() {
  const mutation = useMutation(validateNip);

  return mutation;
}
