import { string, arrayOf } from 'prop-types';
import { Title, Subtitle, Description } from '../page';

export function HeaderText({ type, text, name }) {
  let Component;
  switch (type) {
    case 'title':
      Component = Title;
      break;
    case 'subtitle':
      Component = Subtitle;
      break;
    default:
    case 'description':
      Component = Description;
      break;
  }

  const content = text.reduce((newContent, t, i) => {
    newContent.push(t.replace('{{name}}', name));

    if (i !== text.length - 1) {
      newContent.push(<br key={`break-line-${i}`} />);
    }

    return newContent;
  }, []);

  return <Component>{content}</Component>;
}

HeaderText.propTypes = {
  type: string.isRequired,
  text: arrayOf(string).isRequired,
  name: string,
};

HeaderText.defaultProps = {
  name: '',
};

export default HeaderText;
