import { useState } from 'react';
import { func, shape, string } from 'prop-types';
import { Formik, Form } from 'formik';
import { Page, Subtitle, Content } from 'components/common/page';
import { ButtonCard } from 'components/common/card';
import { TextField } from 'components/common/textField';
import { Dropdown } from 'components/common/dropdown';
import { SubmitButton } from 'components/common/submitButton';
import { useSendQuestion } from 'hooks/useSendQuestion';
import { choices, tenureOptions } from './config';
import Yup from 'utils/yup';

const extraStepOptions = ['Empleado', 'Negocio propio'];

const schema = Yup.object().shape({
  occupancyInstitution: Yup.string().required(
    'Ingresa el nombre de la empresa',
  ),
});

export function OccupancyForm({ nextStep, prevStep, userInfo, setUserInfo }) {
  const { name, occupancyType, occupancyInstitution, occupancyTenure } =
    userInfo || {};
  const [occupancy, setOccupancy] = useState(() => occupancyType || '');
  const { status, mutate, mutateAsync } = useSendQuestion();
  const hasOccupancy = !!occupancy && extraStepOptions.includes(occupancy);

  const onChoiceSelect = (choice) => () => {
    mutate(
      { key: 'occupancy_type', value: choice },
      {
        onSuccess: () => {
          setOccupancy(choice);
          setUserInfo({
            ...userInfo,
            occupancyType: choice,
          });
          if (!extraStepOptions.includes(choice)) {
            nextStep();
          }
        },
      },
    );
  };

  const onSubmit = async (values) => {
    try {
      const { occupancyTenure, occupancyInstitution } = values;
      await mutateAsync({
        key: 'occupancy_tenure',
        value: {
          tenure: occupancyTenure,
          institution: occupancyInstitution,
        },
      });

      setUserInfo({
        ...userInfo,
        ...values,
      });
      localStorage.setItem(
        'applicationState',
        JSON.stringify({
          ...userInfo,
          lastStep: 'occupancy',
        }),
      );
      nextStep();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Page>
      <Subtitle className="pt5">
        {hasOccupancy ? (
          <>
            {name} platícame, <br /> ¿Cuánto llevas en tu empleo actual?
          </>
        ) : (
          <>¿A qué te dedicas {name}?</>
        )}
      </Subtitle>
      <Content>
        {!hasOccupancy ? (
          <>
            {choices.map((c, i) => (
              <ButtonCard
                key={`${c}-${i}`}
                type="button"
                onClick={onChoiceSelect(c.value)}
                active={occupancyType === c.value}
                disabled={status === 'loading'}
              >
                {c.text}
              </ButtonCard>
            ))}
            <SubmitButton hideSubmitButton prevStep={prevStep} />
          </>
        ) : (
          <Formik
            onSubmit={onSubmit}
            initialValues={{
              occupancyInstitution: occupancyInstitution || '',
              occupancyTenure: occupancyTenure || '1',
            }}
            validationSchema={schema}
            validateOnMount
          >
            {({ isValid }) => (
              <Form>
                <Dropdown
                  className="mt5"
                  name="occupancyTenure"
                  options={tenureOptions}
                  labelText={
                    occupancy === 'Empleado'
                      ? 'Tiempo en empleo actual'
                      : 'Tiempo con tu negocio propio'
                  }
                />
                <TextField
                  className="mt5"
                  name="occupancyInstitution"
                  placeholder="Escribe el nombre de la empresa"
                  autoComplete="off"
                  type="text"
                />
                <SubmitButton
                  type="submit"
                  disabled={!isValid || status === 'loading'}
                  prevStep={() => setOccupancy('')}
                >
                  <span className="fw6">¡Listo!</span> Siguiente
                </SubmitButton>
              </Form>
            )}
          </Formik>
        )}
      </Content>
    </Page>
  );
}

OccupancyForm.propTypes = {
  nextStep: func.isRequired,
  prevStep: func.isRequired,
  userInfo: shape({
    name: string,
  }).isRequired,
  setUserInfo: func.isRequired,
};

export default OccupancyForm;
