import { func, string, bool, node } from 'prop-types';
import { useField } from 'formik';
import styled from 'styled-components';

export function TextField(props) {
  const {
    name,
    type,
    labelText,
    normalize,
    disabled,
    leftIcon,
    ...rest
  } = props;
  const [field, meta, helpers] = useField(props);

  const normalizeValue = (e) => {
    const newValue = normalize(e.target.value);
    helpers.setValue(newValue);
  };

  return (
    <FieldGroup>
      <Label
        htmlFor={name}
        topPosition={field.value?.length > 0 && '-24px'}
        error={meta.error && meta.touched && '#F44771'}
      >
        {labelText}
      </Label>
      {leftIcon && !!field.value && (
        <LeftIcon color={meta.error && meta.touched ? '#F44771' : '#3E8AF8'}>
          {leftIcon}
        </LeftIcon>
      )}
      <Input
        {...rest}
        {...field}
        onChange={normalize ? normalizeValue : field.onChange}
        onBlur={field.onBlur}
        value={field.value || ''}
        type={type}
        autocomplete={
          (meta.initialValue === field?.value && field?.value?.length) ||
          (disabled && field?.value?.length > 0)
        }
        color={
          meta.error && meta.touched
            ? '#F44771'
            : meta.touched || field.value
            ? '#3E8AF8'
            : '#95B0D7'
        }
        hasLeftIcon={!!leftIcon}
      />
      {meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
    </FieldGroup>
  );
}

TextField.propTypes = {
  name: string.isRequired,
  labelText: string,
  type: string,
  normalize: func,
  disabled: bool,
  leftIcon: node,
};

TextField.defaultProps = {
  labelText: '',
  type: 'text',
  normalize: null,
  disabled: false,
  leftIcon: null,
};

export default TextField;

const Label = styled.label.attrs({
  className: 'absolute z-0 lighter top-0 left-0 w-100 dgt-input-transition',
})`
  line-height: 21px;
  font-weight: ${(props) => (props.topPosition ? '900' : '500')};
  top: ${(props) => props.topPosition};
  font-size: ${(props) => (props.topPosition ? '15px' : '16px')};
  color: ${(props) => props.topPosition && '#3E8AF8'};
  color: ${(props) => props.error};
`;

const Input = styled.input.attrs({
  className: 'z-0 bg-transparent outline-0 w-100 dgt-only-bb dgt-input h2 pl2',
})`
  background: ${(props) => props.autocomplete && '#e1edfb'} !important;
  border-radius: 10px 10px 0px 0px;
  border-color: ${(props) => props.color};
  color: ${(props) => props.color};
  padding-left: ${(props) => (props.hasLeftIcon ? 10 : 0)}px;
`;

const FieldGroup = styled.div.attrs({
  className: 'mv5 flex flex-column relative',
})`
  @media (max-width: 360px) {
    margin-top: 1.3rem;
    margin-bottom: 1.3rem;
  }
`;

const ErrorMessage = styled.div.attrs({
  className: 'state-error mt1',
})`
  font-size: 13px;
`;

const LeftIcon = styled.div.attrs({
  className: 'absolute left-0 z-1',
})`
  top: 7px;
  color: ${(props) => props.color};
`;
