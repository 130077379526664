export const kinship = [
  {
    value: 'Hermano',
    label: 'Hermano',
  },
  {
    value: 'Pareja',
    label: 'Pareja',
  },
  {
    value: 'Compañero de trabajo',
    label: 'Compañero de trabajo',
  },
  {
    value: 'Padre/Madre',
    label: 'Padre / Madre',
  },
  {
    value: 'Amigo',
    label: 'Amigo',
  },
  {
    value: 'other',
    label: 'Otro',
  },
];
