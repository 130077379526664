import { useMutation } from 'react-query';
import api from 'api';

async function createLoanRequest({ email, phone }) {
  const response = await api.post('/loan_request', {
    email,
    phone: phone.replace(/()\s/g, ''),
  });

  return response.data;
}

export function useCreateApplication() {
  const mutation = useMutation(createLoanRequest);

  return mutation;
}
