export function normalizePhone(value) {
  const numbers = value.replace(/[^\d.]/g, '');
  const m = numbers.match(/^(\d{1,2})(\d{1,4})?(\d{1,4})?/);
  const length = numbers.length;

  if (length > 6) {
    return `(${m[1]}) ${m[2]} ${m[3]}`.trim();
  } else if (length > 2) {
    return `(${m[1]}) ${m[2]}`.trim();
  }
  return numbers;
}

export function normalizeZipCode(value) {
  const numbers = value.replace(/[^\d.]/g, '');
  const length = numbers.length;

  if (length > 5) {
    return numbers.substr(0, 5);
  }

  return numbers;
}

export function normalizeDay(value) {
  const numbers = value.replace(/[^\d.]/g, '');
  const m = numbers.match(/^(\d{1,2})?/);

  if (numbers.length > 1) {
    return `${m[1]}`.trim();
  }
  return numbers;
}

export function normalizeYear(value) {
  const numbers = value.replace(/[^\d.]/g, '');
  const m = numbers.match(/^(\d{1,4})?/);

  if (numbers.length > 1) {
    return `${m[1]}`.trim();
  }
  return numbers;
}

export function formatMoney(value, fixed = 0) {
  const re = new RegExp(/\d(?=(\d{3})+$)/, 'g');
  const hasDot = /\./g.test(value);
  const [num, decimals] = String(value).split('.');
  const money = num.replace(re, '$&,');

  if (hasDot && fixed > 0) {
    return `${money}.${decimals.substr(0, fixed)}`;
  }

  return money;
}

export function normalizeMoney(value, previousValue) {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d.]/g, '');
  const hasDot = /\./g.test(onlyNums);
  const [m, decimals] = onlyNums.split('.');
  const money = formatMoney(parseFloat(m) || 0);

  if (onlyNums.length === 0) {
    return '';
  }

  if (hasDot) {
    return `${money}.${decimals.substr(0, 2)}`;
  }

  return money;
}
