import { useQuery } from 'react-query';
import { useApplication } from 'providers/applicationProvider';
import { getInitialState } from 'utils/applicationsUtils';
import api from 'api';

async function getApplication() {
  // ToDo: replace this endpoint for one from hapi hades
  const response = await api.get('/loan_request');
  return response.data;
}

export function useGetApplicationState(options = {}) {
  const { setStepsInfo, setUserInfo } = useApplication();
  return useQuery('applicationState', getApplication, {
    ...options,
    onSuccess: (data) => {
      const { userInfo, currentStep } = getInitialState(data);
      setStepsInfo({ steps: data.schema, currentStep });
      setUserInfo(userInfo);
    },
  });
}
