import api from '.';

export function setupInterceptors() {
  api.interceptors.request.use(
    async (config) => {
      const params = new URLSearchParams(window.location.search);
      const token = params.has('t') ? params.get('t') : null;
      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        };
      }
      return config;
    },
    (error) => Promise.reject(error),
  );
  // api.interceptors.response.use(
  //   (response) => response,
  //   async (error) => {
  //     const config = error.config;
  //     if (error?.response?.status === 401) {
  //       window.location.replace('/');
  //       return api(config);
  //     }
  //     return Promise.reject(error);
  //   },
  // );
}

export default setupInterceptors;
