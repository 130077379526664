import { useEffect, useState } from 'react';
import { func, bool, shape } from 'prop-types';
import { Formik, Form as FormikForm } from 'formik';
import styled from 'styled-components';
import { Page, Subtitle, Description, Content } from 'components/common/page';
import { SubmitButton } from 'components/common/submitButton';
import TextField from 'components/common/textField';
import { Dropdown } from 'components/common/dropdown';
import { normalizeZipCode } from 'utils/normalizers';
import { useGetLocations } from 'hooks/useGetLocations';
import { useSetLocaiton } from 'hooks/useSetLocation';
import { useSetAddress } from 'hooks/useSetAddress';
import Yup from 'utils/yup';

const schema = Yup.object().shape({
  street: Yup.string().required('Ingresa tu calle'),
  extNumber: Yup.string().required('Ingresa tu número exterior'),
  zipCode: Yup.string()
    .required('Ingresa tu código postal')
    .min(5, 'Ingresa un código postal valido'),
  colony: Yup.string().required('Ingresa tu colonia'),
  city: Yup.string().required(),
  state: Yup.string().required(),
});

const fileds = ['colony', 'city', 'state'];

const Form = ({
  values,
  setFieldValue,
  setFieldTouched,
  isValid,
  prevStep,
  isLoading,
}) => {
  const [colonies, setColonies] = useState([]);
  const { zipCode } = values;
  const { status: getLocationsStatus } = useGetLocations(zipCode, {
    enabled: zipCode?.length === 5,
    retry: 0,
    refetchOnWindowFocus: false,
    onSuccess: async (data) => {
      setColonies(data.colonias.map((c) => ({ value: c, label: c })));
      setFieldValue('colony', data.colonias[0]);
      setFieldValue('city', data.municipio);
      setFieldValue('state', data.estado);
      await setFieldTouched('colony', true);
      await setFieldTouched('city', true);
      await setFieldTouched('state', true);
    },
  });

  useEffect(() => {
    if (zipCode?.length < 5) {
      setColonies([]);
      fileds.forEach((f) => {
        setFieldValue(f, '');
        setFieldTouched(f, false);
      });
    }
  }, [zipCode, setFieldValue, setFieldTouched]);

  return (
    <FormikForm autoComplete="off">
      {getLocationsStatus === 'error' && (
        <ErrorMessage>
          No se encontro tu código postal. <br /> Intentalo de nuevo.
        </ErrorMessage>
      )}
      <TextField name="street" labelText="Calle" type="text" className="mb4" />
      <TextField
        name="extNumber"
        labelText="Número Exterior"
        type="text"
        className="mb4"
      />
      <TextField
        name="intNumber"
        labelText="Número interior *Opcional"
        type="text"
        className="mb4"
      />
      <TextField
        name="zipCode"
        labelText="Código postal"
        type="text"
        className="mb4"
        normalize={normalizeZipCode}
      />
      {colonies.length < 2 ? (
        <TextField
          name="colony"
          labelText="Colonia"
          type="text"
          className="mb4"
          disabled={getLocationsStatus !== 'success'}
        />
      ) : (
        <DropdownContainer>
          <Dropdown
            name="colony"
            options={colonies}
            labelText="Selecciona una colonia"
          />
        </DropdownContainer>
      )}
      <TextField
        name="city"
        labelText="Municipio"
        type="text"
        className="mb4"
        disabled
      />
      <TextField
        name="state"
        labelText="Estado"
        type="text"
        className="mb4"
        disabled
      />
      <SubmitButton
        type="submit"
        disabled={!isValid || isLoading}
        prevStep={prevStep}
      >
        <span className="fw6">¡Listo!</span> Siguiente
      </SubmitButton>
    </FormikForm>
  );
};

Form.propTypes = {
  values: shape({}).isRequired,
  prevStep: func.isRequired,
  nextStep: func.isRequired,
  isValid: bool.isRequired,
  setFieldValue: func.isRequired,
  setFieldTouched: func.isRequired,
  isLoading: bool.isRequired,
};

export function LocationForm({ nextStep, prevStep, userInfo, setUserInfo }) {
  const { mutate: locationMutate } = useSetLocaiton();
  const {
    mutateAsync: setAddressMutate,
    status: setAddressStatus,
  } = useSetAddress();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const {
          coords: { latitude, longitude },
        } = position;
        locationMutate({ latitude, longitude });
      });
    }
  }, [locationMutate]);

  const onSubmit = async (values) => {
    try {
      await setAddressMutate(values);
      setUserInfo({
        ...userInfo,
        ...values,
      });
      nextStep();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Page>
      <Description className="pt5">
        Perfecto, ahora cuéntanos un poco más de ti
      </Description>
      <Subtitle> Platícanos ¿Dónde vives?</Subtitle>
      <Content>
        <Formik
          initialValues={{
            street: userInfo?.street || '',
            zipCode: userInfo?.zipCode || '',
            colony: userInfo?.colony || '',
            state: userInfo?.state || '',
            city: userInfo?.city || '',
            extNumber: userInfo?.extNumber || '',
            intNumber: userInfo?.intNumber || '',
          }}
          validationSchema={schema}
          validateOnMount
          onSubmit={onSubmit}
        >
          {(formikProps) => (
            <Form
              {...formikProps}
              nextStep={nextStep}
              prevStep={prevStep}
              isLoading={setAddressStatus === 'loading'}
            />
          )}
        </Formik>
      </Content>
    </Page>
  );
}

LocationForm.propTypes = {
  prevStep: func.isRequired,
};

const ErrorMessage = styled.p.attrs({
  className: 'tc ma0 state-error b',
})``;

const DropdownContainer = styled.div.attrs({
  className: 'relative nt4 pb4',
})``;

export default LocationForm;
